import React, { useState } from 'react';

import withLayout from '../components/withLayout';
import Heading from '../components/molecules/Heading';
import {
  FaArrowLeft,
  FaArrowUp,
  FaGlobeEurope,
  FaPlus,
  FaRocket,
} from 'react-icons/all';
import styled from 'styled-components';
import breakpoints from '../utils/breakpoints';
import { containerStyles, deceleratedEasing } from '../utils/styles';
import { messages } from '../i18n/translations';
import { FormattedMessage, useIntl } from 'react-intl';
import { darken } from 'polished';
import LogoProvider from '../components/images/logotypes/LogoProvider';
import Section from '../components/atoms/Section';
import IconButtonCircle from '../components/atoms/IconButtonCircle';

const Container = styled.div`
  ${containerStyles};

  @media ${breakpoints.lg} {
    margin: 0 auto;
  }
`;

const StyledSection = styled(Section)`
  position: relative;
`;

const BackToTop = styled.button`
  align-items: center;
  background-color: #326dad;
  border: 0;
  border-radius: 0.4375rem 0 0.4375rem 0;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 1.125rem;
  height: 2.625rem;
  justify-content: center;
  outline: none;
  width: 2.625rem;

  @media ${breakpoints.lg} {
    font-size: 1.625rem;
    height: 3.75rem;
    width: 3.75rem;
  }

  &:hover {
    background-color: ${darken(0.1, '#326DAD')};
  }
`;

const Buttons = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 1.875rem;

  @media ${breakpoints.lg} {
    margin-top: 3.75rem;
  }
`;

const ExpandFilters = styled(IconButtonCircle)`
  display: inline-flex;
  padding: 0;
  font-size: 1.125rem;
  height: 2.25rem;
  margin-bottom: 1.25rem;
  margin-right: 0.625rem;
  width: 2.25rem;

  @media ${breakpoints.lg} {
    font-size: 1.3125rem;
    height: 3.125rem;
    margin-right: 1.25rem;
    width: 3.125rem;
  }
`;

const ClearFilters = styled(ExpandFilters)`
  background-color: #cbcccb;

  & > svg {
    transform: rotate(45deg);
  }

  &:hover {
    background-color: ${darken(0.1, '#CBCCCB')};
  }

  &:active {
    background-color: ${darken(0.3, '#CBCCCB')};
  }
`;

const ExpandItems = styled(ExpandFilters)`
  height: 2.625rem;
  margin: 0;
  width: 2.625rem;

  @media ${breakpoints.lg} {
    font-size: 1.5rem;
    height: 3.75rem;
    width: 3.75rem;
  }
`;

const Filter = styled.button`
  background-color: ${props => (props.active ? '#FFC331' : '#EDEDED')};
  border: 0;
  border-radius: 0.4375rem;
  cursor: pointer;
  font-size: 0.9375rem;
  line-height: 1.0625rem;
  margin-bottom: 1.25rem;
  margin-right: 0.625rem;
  outline: none;
  padding: 0.5625rem 0.625rem;
  transition: background-color 200ms ${deceleratedEasing};

  @media ${breakpoints.lg} {
    border-radius: 0.625rem;
    font-size: 1.3125rem;
    line-height: 1.5625rem;
    margin-right: 1.25rem;
    padding: 0.8125rem 0.9375rem;
  }

  &:hover {
    background-color: ${props =>
      props.active ? darken(0.1, '#FFC331') : '#CBCCCB'};
  }
`;

const FiltersContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  border: 0.0625rem solid #d9d9d9;
  border-radius: 0.4375rem;
  margin-bottom: 1.25rem;

  @media ${breakpoints.md} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    width: calc(50% - 2.5rem);
  }

  @media ${breakpoints.lg} {
    align-items: center;
    border-radius: 0.625rem;
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;

const ItemBottom = styled.div`
  border-top: 0.0625rem solid #d9d9d9;
  display: flex;

  @media ${breakpoints.md} {
    flex: 0 0 auto;
    margin-top: auto;
  }

  @media ${breakpoints.lg} {
    align-self: stretch;
    border-left: 0.0625rem solid #d9d9d9;
    border-top: 0;
    flex-direction: column;
    margin-top: 0;
    width: 18.125rem;
  }
`;

const ItemDescription = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0 0.9375rem 1.125rem;

  @media ${breakpoints.lg} {
    flex: 1 1 auto;
    font-size: 1.0625rem;
    line-height: 1.5625rem;
    padding: 1.25rem 3.125rem 1.25rem 1.25rem;
  }
`;

const ItemLink = styled.a`
  color: #004a99;
  text-decoration: none;

  @media ${breakpoints.lg} {
    align-items: center;
    display: flex;
    max-width: 100%;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const ItemLinkContainer = styled.div`
  align-items: center;
  border-left: 0.0625rem solid #d9d9d9;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 0.625rem 1.375rem;
  position: relative;

  @media ${breakpoints.lg} {
    border-left: 0;
    justify-content: flex-start;
    min-height: 5.625rem;
    order: 1;
    padding: 0.625rem 1.5625rem;
    width: 100%;
  }

  &::after {
    background-image: linear-gradient(
      to right,
      #d9d9d9 0%,
      rgba(238, 238, 238, 0) 100%
    );
    bottom: 0;
    display: block;
    height: 0.0625rem;
    left: 0;
    position: absolute;
    right: 0;

    @media ${breakpoints.lg} {
      content: '';
    }
  }
`;

const ItemLinkIcon = styled.div`
  color: #14ade3;
  font-size: 1.3125rem;
  text-align: center;

  @media ${breakpoints.lg} {
    flex: 0 0 auto;
    font-size: 1.5rem;
    margin-right: 0.625rem;
  }

  svg {
    display: block;
    margin: 0 auto;

    @media ${breakpoints.lg} {
      margin: 0;
    }
  }
`;

const ItemLinkText = styled.div`
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1.0625rem;
  margin-top: 0.25rem;

  @media ${breakpoints.lg} {
    flex: 1 1 auto;
    font-size: 1.5rem;
    line-height: 1.5625rem;
    margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ItemLinkTextDesktop = styled(ItemLinkText)`
  display: none;

  @media ${breakpoints.lg} {
    display: block;
  }
`;

const ItemLinkTextMobile = styled(ItemLinkText)`
  @media ${breakpoints.lg} {
    display: none;
  }
`;

const ItemLogo = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 7.5rem;
  padding: 1.25rem 2.8125rem;

  @media ${breakpoints.md} {
    flex: 0 0 auto;
  }

  @media ${breakpoints.lg} {
    align-items: center;
    display: flex;
    min-height: 13.75rem;
    width: 19.375rem;
  }

  .gatsby-image-wrapper {
    flex-grow: 1;
  }
`;

const ItemTag = styled.span`
  background-color: #ededed;
  border-radius: 0.125rem;
  display: inline-block;
  font-size: 0.625rem;
  line-height: 0.75rem;
  margin-bottom: 0.4375rem;
  margin-right: 0.4375rem;
  padding: 0.25rem;
  text-align: left;

  @media ${breakpoints.lg} {
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 0.625rem;
    margin-right: 0.625rem;
    padding: 0.25rem 0.4375rem;
  }
`;

const ItemTags = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  padding: 0.75rem 0.9375rem 0.4375rem;

  @media ${breakpoints.lg} {
    flex: 0 0 auto;
    margin: auto 0;
    order: 2;
    padding: 0.875rem 0.875rem 0.25rem;
    width: 100%;
  }
`;

const List = styled.div`
  margin: 3.75rem -0.9375rem 0;

  @media ${breakpoints.md} {
    display: flex;
    flex-wrap: wrap;
  }

  @media ${breakpoints.lg} {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
`;

const Separator = styled.div`
  background-image: linear-gradient(
    to right,
    #d9d9d9 0%,
    rgba(238, 238, 238, 0) 100%
  );
  display: none;
  height: 0.0625rem;
  margin-top: 2.5rem;

  @media ${breakpoints.lg} {
    display: block;
  }
`;

const Startups = () => {
  const intl = useIntl();
  const [activeFilters, setActiveFilters] = useState([]);
  const [itemsLimit, setItemsLimit] = useState(10);
  const [limitFilters, setLimitFilters] = useState(true);

  const items = messages[intl.locale].startups.items;
  const tags = messages[intl.locale].startups.tags;
  const itemsPerPage = 10;

  const clearFilters = () => {
    setActiveFilters([]);
  };

  const getFilters = () => {
    if (limitFilters) {
      return tags.slice(0, 4);
    }

    return tags;
  };

  const getItemLink = www => {
    if (www.substring(0, 4) !== 'http') {
      return `https://${www}`;
    }

    return www;
  };

  const getItemLinkText = www =>
    www.replace(/https:\/\/|http:\/\/|www\.|\/$/g, '');

  const getSelectedItems = () => {
    return activeFilters.length === 0 || activeFilters.length === tags.length
      ? items
      : items.filter(item =>
          item.tags.some(tag => activeFilters.includes(tag))
        );
  };

  const handleFilterClick = tag => {
    setItemsLimit(itemsPerPage);

    if (activeFilters.includes(tag)) {
      setActiveFilters(activeFilters.filter(filter => filter !== tag));
      return;
    }

    setActiveFilters([...activeFilters, tag]);
  };

  const scrollToFilters = () => {
    document.documentElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  };

  const selectedItems = getSelectedItems();

  return (
    <StyledSection>
      <Container>
        <Heading icon={<FaRocket />} title="startups.heading" />

        <FiltersContainer id="filters">
          <ClearFilters onClick={clearFilters}>
            <FaPlus />
          </ClearFilters>

          {getFilters().map(tag => (
            <Filter
              active={activeFilters.includes(tag)}
              key={tag}
              onClick={() => handleFilterClick(tag)}
            >
              {tag}
            </Filter>
          ))}

          {limitFilters && (
            <ExpandFilters onClick={() => setLimitFilters(false)}>
              <FaPlus />
            </ExpandFilters>
          )}

          {!limitFilters && (
            <ExpandFilters onClick={() => setLimitFilters(true)}>
              <FaArrowLeft />
            </ExpandFilters>
          )}
        </FiltersContainer>

        <Separator />

        <List>
          {selectedItems.slice(0, itemsLimit).map(item => (
            <Item key={item.startup}>
              <ItemLogo>
                <LogoProvider name={item.startup} />
              </ItemLogo>

              <ItemDescription>{item.description}</ItemDescription>

              <ItemBottom>
                <ItemTags>
                  {item.tags.map(tag => (
                    <ItemTag key={tag}>{tag}</ItemTag>
                  ))}
                </ItemTags>

                <ItemLinkContainer>
                  <ItemLink href={getItemLink(item.www)} target="_blank" rel="noopener noreferrer">
                    <ItemLinkIcon>
                      <FaGlobeEurope />
                    </ItemLinkIcon>

                    <ItemLinkTextDesktop>
                      {getItemLinkText(item.www)}
                    </ItemLinkTextDesktop>

                    <ItemLinkTextMobile>
                      <FormattedMessage id="startups.www" />
                    </ItemLinkTextMobile>
                  </ItemLink>
                </ItemLinkContainer>
              </ItemBottom>
            </Item>
          ))}
        </List>

        <Buttons>
          <div />
          {selectedItems.length > itemsLimit && (
            <ExpandItems
              onClick={() => setItemsLimit(itemsLimit + itemsPerPage)}
            >
              <FaPlus />
            </ExpandItems>
          )}

          <BackToTop onClick={scrollToFilters}>
            <FaArrowUp />
          </BackToTop>
        </Buttons>
      </Container>
    </StyledSection>
  );
};

export default withLayout(React.memo(Startups));
