import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const CompanyProtectAuthLogo = props => {
  const data = useStaticQuery(graphql`
query {
  placeholderImage: file(relativePath: { eq: "logotypes/66.png" }) {
    childImageSharp {
      fluid(maxWidth: 300, quality: 100) {
      ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
}
`);

  return <Img {...props} fluid={data.placeholderImage.childImageSharp.fluid} />;
};

export default CompanyProtectAuthLogo;
