import React, { createElement } from 'react';
import Company2takeitLogo from './Company2takeitLogo';
import CompanyAAZZURLogo from './CompanyAAZZURLogo';
import CompanyabyssglassGroupLogo from './CompanyabyssglassGroupLogo';
import CompanyAIInvestmentsLogo from './CompanyAIInvestmentsLogo';
import CompanyAIBUSTERSLogo from './CompanyAIBUSTERSLogo';
import CompanyAlgolyticsTechnologiesLogo from './CompanyAlgolyticsTechnologiesLogo';
import CompanyAPPLICAAILogo from './CompanyAPPLICAAILogo';
import CompanyAutentiLogo from './CompanyAutentiLogo';
import CompanyBancovoLogo from './CompanyBancovoLogo';
import CompanybanqUPLogo from './CompanybanqUPLogo';
import CompanyBeesfundLogo from './CompanyBeesfundLogo';
import CompanyBSaferspzooLogo from './CompanyBSaferspzooLogo';
import CompanyBillonLogo from './CompanyBillonLogo';
import CompanyBillTechLogo from './CompanyBillTechLogo';
import CompanyBlockCoreTokenizationClubbitSecuritiesLogo from './CompanyBlockCoreTokenizationClubbitSecuritiesLogo';
import CompanyCarrotspotLogo from './CompanyCarrotspotLogo';
import CompanyCashDirectorSALogo from './CompanyCashDirectorSALogo';
import CompanyCashapLogo from './CompanyCashapLogo';
import CompanyCashyLogo from './CompanyCashyLogo';
import CompanyChatbotizeLogo from './CompanyChatbotizeLogo';
import CompanyChatForceLogo from './CompanyChatForceLogo';
import CompanyCoinfirmLogo from './CompanyCoinfirmLogo';
import CompanyCoinquistaSALogo from './CompanyCoinquistaSALogo';
import CompanyCreamfinanceLogo from './CompanyCreamfinanceLogo';
import CompanyCluifyLogo from './CompanyCluifyLogo';
import CompanyDevskillerLogo from './CompanyDevskillerLogo';
import CompanyDigitalFingerprintsLogo from './CompanyDigitalFingerprintsLogo';
import CompanyDigitalTeammatesSALogo from './CompanyDigitalTeammatesSALogo';
import CompanyDronncomLogo from './CompanyDronncomLogo';
import CompanyEdwardLogo from './CompanyEdwardLogo';
import CompanyEmplocityspzooLogo from './CompanyEmplocityspzooLogo';
import CompanyExChordLogo from './CompanyExChordLogo';
import CompanyFandlaFaktoringspzooLogo from './CompanyFandlaFaktoringspzooLogo';
import CompanyfeeCOMPASSLogo from './CompanyfeeCOMPASSLogo';
import CompanyFinanteqSALogo from './CompanyFinanteqSALogo';
import CompanyFiniataLogo from './CompanyFiniataLogo';
import CompanyFitqbeLogo from './CompanyFitqbeLogo';
import CompanyGoRODOplLogo from './CompanyGoRODOplLogo';
import CompanyGratifuLogo from './CompanyGratifuLogo';
import CompanyHomeProfitLogo from './CompanyHomeProfitLogo';
import CompanyHotailorsLogo from './CompanyHotailorsLogo';
import CompanyICSolutionsspzooLogo from './CompanyICSolutionsspzooLogo';
import CompanyIdenttLogo from './CompanyIdenttLogo';
import CompanyIgnibitSALogo from './CompanyIgnibitSALogo';
import CompanyInvestsuiteLogo from './CompanyInvestsuiteLogo';
import CompanyisourcetecLogo from './CompanyisourcetecLogo';
import CompanyJusttagLogo from './CompanyJusttagLogo';
import CompanyKontomatikLogo from './CompanyKontomatikLogo';
import CompanyKredytmarketLogo from './CompanyKredytmarketLogo';
import CompanyLeaseLinkSpzooLogo from './CompanyLeaseLinkSpzooLogo';
import CompanyLogicAILogo from './CompanyLogicAILogo';
import CompanyLUNALogo from './CompanyLUNALogo';
import CompanyMigamLogo from './CompanyMigamLogo';
import CompanyMintCloudLogo from './CompanyMintCloudLogo';
import CompanyMyPłacimyspzooLogo from './CompanyMyPłacimyspzooLogo';
import CompanyNapoleonCatLogo from './CompanyNapoleonCatLogo';
import CompanyNeontriLogo from './CompanyNeontriLogo';
import CompanyNethoneLogo from './CompanyNethoneLogo';
import CompanyNextropeLogo from './CompanyNextropeLogo';
import CompanyNuDeltaLogo from './CompanyNuDeltaLogo';
import CompanyPayUkraineLogo from './CompanyPayUkraineLogo';
import CompanyPayPoLogo from './CompanyPayPoLogo';
import CompanyPergaminLogo from './CompanyPergaminLogo';
import CompanyplacemeLogo from './CompanyplacemeLogo';
import CompanyProtectmeLogo from './CompanyProtectmeLogo';
import CompanyProtectAuthLogo from './CompanyProtectAuthLogo';
import CompanyProxicloudLogo from './CompanyProxicloudLogo';
import CompanyQuantumcxLogo from './CompanyQuantumcxLogo';
import CompanyRachuneoLogo from './CompanyRachuneoLogo';
import CompanyRampNetworkLogo from './CompanyRampNetworkLogo';
import CompanyRunvidoSpzooLogo from './CompanyRunvidoSpzooLogo';
import CompanySCFOLogo from './CompanySCFOLogo';
import CompanySentiOneLogo from './CompanySentiOneLogo';
import CompanyShareHireLogo from './CompanyShareHireLogo';
import CompanySherlockWasteLogo from './CompanySherlockWasteLogo';
import CompanySMEOSALogo from './CompanySMEOSALogo';
import CompanySolfyLogo from './CompanySolfyLogo';
import CompanySportbonusLogo from './CompanySportbonusLogo';
import CompanySupplierPlusLogo from './CompanySupplierPlusLogo';
import CompanySurf4TaxLogo from './CompanySurf4TaxLogo';
import CompanySygnetaiLogo from './CompanySygnetaiLogo';
import CompanySyneriseSALogo from './CompanySyneriseSALogo';
import CompanyTensorflightLogo from './CompanyTensorflightLogo';
import CompanyTransparentDataLogo from './CompanyTransparentDataLogo';
import CompanyTrefixLogo from './CompanyTrefixLogo';
import CompanyTuPrawnikLogo from './CompanyTuPrawnikLogo';
import CompanyTutorialOnlineLogo from './CompanyTutorialOnlineLogo';
import CompanyTwistoPolskaLogo from './CompanyTwistoPolskaLogo';
import CompanyUpswotLogo from './CompanyUpswotLogo';
import CompanyVindicatplLogo from './CompanyVindicatplLogo';
import CompanyVintomspzooLogo from './CompanyVintomspzooLogo';
import CompanyVoiceLabAILogo from './CompanyVoiceLabAILogo';
import CompanyWandleeLogo from './CompanyWandleeLogo';
import CompanyWaywerLogo from './CompanyWaywerLogo';
import CompanyElasticCloudSolutionsLogo from './CompanyElasticCloudSolutionsLogo';
import CompanyWorkBeeLogo from './CompanyWorkBeeLogo';
import CompanyXOXOWiFiLogo from './CompanyXOXOWiFiLogo';
import CompanyZenCardspzooLogo from './CompanyZenCardspzooLogo';
import CompanyZweryfikujFirmęplLogo from './CompanyZweryfikujFirmęplLogo';
import CompanyPublboxLogo from './CompanyPublboxLogo';

const components = {
  '2take.it': Company2takeitLogo,
  AAZZUR: CompanyAAZZURLogo,
  'abyss glass Group': CompanyabyssglassGroupLogo,
  'AI Investments': CompanyAIInvestmentsLogo,
  'AI BUSTERS': CompanyAIBUSTERSLogo,
  'Algolytics Technologies': CompanyAlgolyticsTechnologiesLogo,
  'APPLICA.AI': CompanyAPPLICAAILogo,
  Autenti: CompanyAutentiLogo,
  Bancovo: CompanyBancovoLogo,
  banqUP: CompanybanqUPLogo,
  Beesfund: CompanyBeesfundLogo,
  'BSafer sp. z o.o.': CompanyBSaferspzooLogo,
  Billon: CompanyBillonLogo,
  BillTech: CompanyBillTechLogo,
  'BlockCore (Tokenization Club + bitSecurities)': CompanyBlockCoreTokenizationClubbitSecuritiesLogo,
  Carrotspot: CompanyCarrotspotLogo,
  'CashDirector SA': CompanyCashDirectorSALogo,
  Cashap: CompanyCashapLogo,
  Cashy: CompanyCashyLogo,
  Chatbotize: CompanyChatbotizeLogo,
  ChatForce: CompanyChatForceLogo,
  Coinfirm: CompanyCoinfirmLogo,
  'Coinquista S.A.': CompanyCoinquistaSALogo,
  Creamfinance: CompanyCreamfinanceLogo,
  Cluify: CompanyCluifyLogo,
  Devskiller: CompanyDevskillerLogo,
  'Digital Fingerprints': CompanyDigitalFingerprintsLogo,
  'Digital Teammates SA': CompanyDigitalTeammatesSALogo,
  'Dronn.com': CompanyDronncomLogo,
  Edward: CompanyEdwardLogo,
  'Emplocity sp. z o.o.': CompanyEmplocityspzooLogo,
  ExChord: CompanyExChordLogo,
  'Fandla Faktoring sp. z o.o.': CompanyFandlaFaktoringspzooLogo,
  feeCOMPASS: CompanyfeeCOMPASSLogo,
  'Finanteq SA': CompanyFinanteqSALogo,
  Finiata: CompanyFiniataLogo,
  Fitqbe: CompanyFitqbeLogo,
  'GoRODO.pl': CompanyGoRODOplLogo,
  Gratifu: CompanyGratifuLogo,
  HomeProfit: CompanyHomeProfitLogo,
  Hotailors: CompanyHotailorsLogo,
  'IC Solutions sp. z o.o.': CompanyICSolutionsspzooLogo,
  Identt: CompanyIdenttLogo,
  'Ignibit SA': CompanyIgnibitSALogo,
  Investsuite: CompanyInvestsuiteLogo,
  isourcetec: CompanyisourcetecLogo,
  Justtag: CompanyJusttagLogo,
  Kontomatik: CompanyKontomatikLogo,
  Kredytmarket: CompanyKredytmarketLogo,
  'LeaseLink Sp. z o.o.': CompanyLeaseLinkSpzooLogo,
  LogicAI: CompanyLogicAILogo,
  LUNA: CompanyLUNALogo,
  Migam: CompanyMigamLogo,
  MintCloud: CompanyMintCloudLogo,
  'My Płacimy sp. z o.o.': CompanyMyPłacimyspzooLogo,
  NapoleonCat: CompanyNapoleonCatLogo,
  Neontri: CompanyNeontriLogo,
  Nethone: CompanyNethoneLogo,
  Nextrope: CompanyNextropeLogo,
  NuDelta: CompanyNuDeltaLogo,
  PayUkraine: CompanyPayUkraineLogo,
  PayPo: CompanyPayPoLogo,
  Pergamin: CompanyPergaminLogo,
  placeme: CompanyplacemeLogo,
  'Protect.me': CompanyProtectmeLogo,
  ProtectAuth: CompanyProtectAuthLogo,
  'Proxi.cloud': CompanyProxicloudLogo,
  'Quantum cx': CompanyQuantumcxLogo,
  'Rachuneo - operatorem jest Polskie Aplikacje Transakcyjne': CompanyRachuneoLogo,
  'Ramp Network': CompanyRampNetworkLogo,
  'Runvido Sp. z o.o.': CompanyRunvidoSpzooLogo,
  SCFO: CompanySCFOLogo,
  SentiOne: CompanySentiOneLogo,
  ShareHire: CompanyShareHireLogo,
  'Sherlock Waste': CompanySherlockWasteLogo,
  'SMEO SA': CompanySMEOSALogo,
  Solfy: CompanySolfyLogo,
  Sportbonus: CompanySportbonusLogo,
  SupplierPlus: CompanySupplierPlusLogo,
  Surf4Tax: CompanySurf4TaxLogo,
  'Sygnet.ai': CompanySygnetaiLogo,
  'Synerise SA': CompanySyneriseSALogo,
  Tensorflight: CompanyTensorflightLogo,
  'Transparent Data': CompanyTransparentDataLogo,
  Trefix: CompanyTrefixLogo,
  TuPrawnik: CompanyTuPrawnikLogo,
  'Tutorial Online': CompanyTutorialOnlineLogo,
  'Twisto Polska': CompanyTwistoPolskaLogo,
  Upswot: CompanyUpswotLogo,
  'Vindicat.pl': CompanyVindicatplLogo,
  'Vintom sp. z o.o.': CompanyVintomspzooLogo,
  'VoiceLab.AI': CompanyVoiceLabAILogo,
  Wandlee: CompanyWandleeLogo,
  Waywer: CompanyWaywerLogo,
  'Elastic Cloud Solutions': CompanyElasticCloudSolutionsLogo,
  WorkBee: CompanyWorkBeeLogo,
  'XOXO WiFi': CompanyXOXOWiFiLogo,
  'ZenCard sp. z o.o.': CompanyZenCardspzooLogo,
  'ZweryfikujFirmę.pl': CompanyZweryfikujFirmęplLogo,
  'PublBox': CompanyPublboxLogo,
};

const LogoProvider = ({ name, ...props }) => {
  const component = components[name];

  return component ? createElement(component, props) : name;
};

export default LogoProvider;
