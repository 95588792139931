import styled, { css } from 'styled-components';
import { baseButtonStyles } from '../../utils/styles';

const IconButtonCircle = styled.button`
  ${baseButtonStyles};
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 4.375rem;
  height: 4.375rem;
  font-size: 1.875rem;
  padding: 0.5rem;

  ${({ small }) =>
    small &&
    css`
      padding: 0.5rem;
      width: 2.25rem;
      height: 2.25rem;
      font-size: 1rem;
    `}
`;

export default IconButtonCircle;
